export const CAL_PRODUCT_PAGE_FAQ_QUESTIONS = [
  {
    sectionTitle: 'Calendar Max',
    content: [
      {
        q: `When will Calendar Max be available?`,
        a: `Our latest estimated ship dates are September and October 2024. We will provide updates throughout our production process.`,
      },
      {
        q: 'Why am I paying full price and not a deposit?',
        a: 'We had a limited launch for delivery in Summer 2024. The deposit window has closed and Calendar Max is now available for preorder.',
      },
      {
        q: 'Can I change the style after I ordered?',
        a: 'No, you will need to cancel your order and place a new one in order to change your style or color selection. Please contact our customer service team via email at help@skylightframe.com or phone at 855-921-2901. We are available 7 days a week from 6am-6pm Pacific Time.',
      },
      {
        q: 'How is Calendar Max different from the 15" and 10" Calendars?',
        a: 'Calendar Max offers the same features loved in our 15" and 10" versions, but boasts a 27" diagonal screen and is exclusively designed for wall mounting. With options in two styles (Classic and Shadow Box) and multiple frame colors to choose from, you have the freedom to personalize your Calendar to complement any setting. Plus, you can easily interchange frame colors to adapt to your environment.',
      },
      {
        q: 'How do I install Calendar Max?',
        a: `Installing Calendar Max is easy! It comes with a wall mount and all necessary hardware. For detailed instructions, check out the `,
        text: ` Skylight Calendar Max Installation Guide`,
        link: `https://skylight.zendesk.com/hc/en-us/articles/26600857260827-Skylight-Calendar-Max-Installation-Guide`,
        punctuation: `.`,
      },
      {
        q: `Can Calendar Max be installed in portrait or landscape orientation?`,
        a: `Yes, either orientation.`,
      },
      {
        q: 'What are the different color and style options?',
        a: `Customize your Calendar Max with different style and color options. 
        
        Shadow Box: Sleek metal frame that adds style and depth. Comes in two finishes: Natural Aluminum or Charcoal
        
        Classic: Timeless and contemporary design for any home. This plastic, fitted frame comes in two frame color options: White or Black.`,
      },
      {
        q: `What's the difference between Classic and Shadow Box?`,
        a: `Shadow Box has a thin, metal frame that slightly protrudes adding depth. Classic is a sturdy plastic that closely wraps the Calendar. Both styles are interchangeable.`,
      },
      {
        q: `Can Calendar Max be placed on a stand?`,
        a: `No, Calendar Max must be wall mounted.`,
      },
      {
        q: `Can Calendar Max sync with my existing 15"/10" Calendar?`,
        a: `You currently cannot connect multiple Skylight Calendars, but you can sync the same external calendars (such as Google) to your Calendars.`,
      },
    ],
  },
  {
    sectionTitle: 'How it Works',
    content: [
      {
        q: `Can you tell me the differences between the Skylight Calendar and Photo Frame?`,
        a: `The Skylight frame is a touchscreen display that everyone in the family can send photos to from anywhere and they'll pop right up on the frame instantly. It's designed to be the perfect family-friendly digital photo frame! 
        
        The Skylight Calendar syncs all of the events from different calendars that your family uses and shows them all together on one beautiful, touchscreen display. It also hosts organizational tools such as the new Chore Chart feature which displays organized lists of what each person needs to get done, so they can stay on top of household duties! Additional organizational features include: Meal planning, List building of any kind, and weather forecast at the time and location of your event!`,
      },
      {
        q: 'Do you need an Internet connection to use Skylight Calendar?',
        a: 'Yes, Skylight Calendar needs a WiFi connection at all times to operate.',
      },
      {
        q: 'Is it possible to get on social media or the internet using this product?',
        a: 'No, there is no way to access social media or the internet using our Skylight products.',
      },
      {
        q: 'Does Skylight Calendar run on battery power?',
        a: 'No. Skylight Calendar needs to be plugged in to an electrical outlet to work.',
      },
      {
        q: 'What calendars can I sync with Skylight?',
        a: 'Skylight Calendar currently supports syncing with Google, Apple, Outlook, Yahoo, Cozi, and Readdle Calendars, as well as any calendar with a public sharing link. You can sync calendars using our mobile app or online Skylight Cloud (i.e. app.ourskylight.com).',
      },
      {
        q: 'How do I view and manage synced calendars?',
        a: `You can view and manage the calendars you have synced with Skylight Calendar using the sync icon in the Skylight mobile app or the 'Synced Calendars' section in the Skylight Cloud online. Once your calendars are synced, you can delete them at any time.`,
      },
      {
        q: 'How do I share events to my Skylight?',
        a: `Add your Skylight Calendar's unique email address as an attendee to any event, or sync your entire calendar and as many others as you want with your Skylight Calendar.`,
      },
      {
        q: `Can you add an event to multiple Skylights at once?`,
        a: `Yes, you can invite multiple Skylight's to the event you want them all to have or sync the same calendar to both Skylight's and they will both update in real time as you update that calendar!`,
      },
      {
        q: 'What other features does Skylight Cal have?',
        a: `Skylight Calendar's touchscreen allows you to interact with your events, add/remove events directly onto the device, create Todo/Chore items, view weather forecast at the time and location of your event, update your schedule with a Daily Meal Plan, and view your schedule in Day, Week, and Month views. You can also color code each event on your calendar, as well as create any kind of lists you choose using the 'Lists' feature in your Skylight mobile app and directly on the device.`,
      },
      {
        q: `Does Skylight support chore charts & lists for multiple people?`,
        a: `Yes, with Skylight Calendar you can create chores for multiple people and have as many different kinds of lists as you'd like! A grocery list will be created by default.`,
      },
      {
        q: 'What kind of return policy do you provide?',
        a: `Enjoy peace of mind with a 100% Satisfaction Guarantee. If you don't love your Skylight Calendar, we will offer you a full refund up to 120 days after purchase.`,
      },
      {
        q: 'How many products can I buy in one order?',
        a: 'If you would like to purchase 25 or more Skylight products, please email us at help@skylightframe.com.',
      },
    ],
  },
]

export const INTERNATIONAL_CAL_PRODUCT_PAGE_FAQ_QUESTIONS = [
  {
    sectionTitle: 'How it Works',
    content: [
      {
        q: `Can you tell me the differences between the Skylight Calendar and Photo Frame?`,
        a: `The Skylight frame is a touchscreen display that everyone in the family can send photos to from anywhere and they'll pop right up on the frame instantly. It's designed to be the perfect family-friendly digital photo frame! 
        
        The Skylight Calendar syncs all of the events from different calendars that your family uses and shows them all together on one beautiful, touchscreen display. It also hosts organizational tools such as the new Chore Chart feature which displays organized lists of what each person needs to get done, so they can stay on top of household duties! Additional organizational features include: Meal planning, List building of any kind, and weather forecast at the time and location of your event!`,
      },
      {
        q: 'Do you need an Internet connection to use Skylight Calendar?',
        a: 'Yes, Skylight Calendar needs a WiFi connection at all times to operate.',
      },
      {
        q: 'Is it possible to get on social media or the internet using this product?',
        a: 'No, there is no way to access social media or the internet using our Skylight products.',
      },
      {
        q: 'Does Skylight Calendar run on battery power?',
        a: 'No. Skylight Calendar needs to be plugged in to an electrical outlet to work.',
      },
      {
        q: 'What calendars can I sync with Skylight?',
        a: 'Skylight Calendar currently supports syncing with Google, Apple, Outlook, Yahoo, Cozi, and Readdle Calendars, as well as any calendar with a public sharing link. You can sync calendars using our mobile app or online Skylight Cloud (i.e. app.ourskylight.com).',
      },
      {
        q: 'How do I view and manage synced calendars?',
        a: `You can view and manage the calendars you have synced with Skylight Calendar using the sync icon in the Skylight mobile app or the 'Synced Calendars' section in the Skylight Cloud online. Once your calendars are synced, you can delete them at any time.`,
      },
      {
        q: 'How do I share events to my Skylight?',
        a: `Add your Skylight Calendar's unique email address as an attendee to any event, or sync your entire calendar and as many others as you want with your Skylight Calendar.`,
      },
      {
        q: `Can you add an event to multiple Skylights at once?`,
        a: `Yes, you can invite multiple Skylight's to the event you want them all to have or sync the same calendar to both Skylight's and they will both update in real time as you update that calendar!`,
      },
      {
        q: 'What other features does Skylight Cal have?',
        a: `Skylight Calendar's touchscreen allows you to interact with your events, add/remove events directly onto the device, create Todo/Chore items, view weather forecast at the time and location of your event, update your schedule with a Daily Meal Plan, and view your schedule in Day, Week, and Month views. You can also color code each event on your calendar, as well as create any kind of lists you choose using the 'Lists' feature in your Skylight mobile app and directly on the device.`,
      },
      {
        q: `Does Skylight support chore charts & lists for multiple people?`,
        a: `Yes, with Skylight Calendar you can create chores for multiple people and have as many different kinds of lists as you'd like! A grocery list will be created by default.`,
      },
      {
        q: 'What kind of return policy do you provide?',
        a: `Enjoy peace of mind with a 100% Satisfaction Guarantee. If you don't love your Skylight Calendar, we will offer you a full refund up to 120 days after purchase.`,
      },
    ],
  },
]
