export const DIGITAL_FAQ_QUESTIONS = [
  {
    sectionTitle: `How It Works`,
    content: [
      {
        q: `How do I set up Skylight Digital?`,
        a: ` 1. Download our mobile app on your phone. \n 2. Create your account. \n 3. Select the device where you would like to display your photos. \n 4. Follow the steps on the app to install Skylight on your TV. \n 5. Start uploading!`,
      },
      {
        q: `Can others send photos to my account?`,
        a: `Friends and family can send photos to your devices via email. Just email the photos or videos to your personalized Skylight email address, and they will appear instantly on your device. If you prefer to keep your Skylight Digital Membership private, you can change the settings and only allow certain users to send you photos.`,
      },
      {
        q: `Can I delete photos`,
        a: `You can use the Skylight mobile app or go to app.ourskylight.com to manage your images. You can delete images and add new ones at any time.`,
      },
      {
        q: `How many devices can I use Skylight Digital on?`,
        a: `There is no limit to the number of people that can utilize your Skylight Digital subscription features.`,
      },
    ],
  },
]

export const FIRE_DIGITAL_FAQ_QUESTIONS = [
  {
    sectionTitle: `How It Works`,
    content: [
      {
        q: `How do I set up Skylight Digital?`,
        a: ` 1. Download our mobile app on your phone. \n 2. Create your account. \n 3. Follow the steps in the app to install Skylight on your Fire device. \n 4. Start uploading!`,
      },
      {
        q: `Can others send photos to my account?`,
        a: `Friends and family can send photos to your devices via email. Just email the photos or videos to your personalized Skylight email address, and they will appear instantly on your device. If you prefer to keep your Skylight Digital Membership private, you can change the settings and only allow certain users to send you photos.`,
      },
      {
        q: `Can I delete photos`,
        a: `You can use the Skylight mobile app or go to app.ourskylight.com to manage your images. You can delete images and add new ones at any time.`,
      },
      {
        q: `How many devices can I use Skylight Digital on?`,
        a: `There is no limit to the number of people that can utilize your Skylight Digital subscription features.`,
      },
    ],
  },
]
