export const FAQ_QUESTIONS = [
  {
    sectionTitle: 'How It Works',
    content: [
      {
        q: 'How do I activate my frame?',
        a: 'To activate your Skylight, visit app.ourskylight.com/register. There, you will create your unique Skylight email address (e.g. grandmabetty@ourskylight.com) and receive a 6-digit activation code to type into the frame.',
      },
      {
        q: 'How do I send photos to Skylight?',
        a: 'Simply draft an email with your unique Skylight email address (e.g. grandmabetty@ourskylight.com) as the recipient and include the photos as attachments. The photos will appear on your Skylight in seconds!',
      },
      {
        q: 'Is email the only way to send a photo?',
        a: 'You can use our Cloud Portal (app.ourskylight.com) or mobile app to send photos and videos.',
      },
      {
        q: 'Can others send photos to Skylight?',
        a: `You can share your unique Skylight email address with others so they can email photos to your Skylight. This is also a great way to have everyone in the family send photos to a loved one's Skylight.`,
      },
      {
        q: 'How many photos can Skylight hold?',
        a: 'Skylight is equipped with 8GB of storage, which is room for up to ~8,000 photos.',
      },
      {
        q: 'How many photos can I send at a time?',
        a: 'When sending via email, you can attach up to 30MB of content to share with Skylight. If you are using our mobile app or online Cloud Portal (app.ourskylight.com), you can share up to 100MB of content at a time.',
      },
      {
        q: 'Can I send photos to multiple frames at once?',
        a: 'Yes! You can send photos to multiple frames by including each unique Skylight address in the recipient line of your email. If you have a Plus account, you can also select multiple frames when sending through the mobile app and our online Cloud Portal (app.ourskylight.com).',
      },
      {
        q: 'Does the Skylight Frame have a battery?',
        a: 'No, Skylight does not have a battery. It will only work if it is plugged in.',
      },
    ],
  },
  {
    sectionTitle: 'Gifting Skylight',
    content: [
      {
        q: 'Can I set up Skylight before gifting it to someone?',
        a: 'Yes! You can set up the Skylight with your personal email address and begin sending photos. Once the frame is connected to Wi-Fi and activated, the photos will automatically begin downloading. Additionally, you will be able to transfer ownership of the frame online at app.ourskylight.com',
      },
      {
        q: `What if the person I'm gifting Skylight to doesn't have Wi-Fi?`,
        a: 'Content will display on Skylight with or without a Wi-Fi connection, but it requires WiFi for initial setup and to receive new photos. This can be achieved with a temporary connection using a mobile hotspot. Any photos that are sent to Skylight while it is disconnected will queue for download until it is connected once more.',
      },
      {
        q: 'Do you have a gift option in your orders?',
        a: 'Skylight comes in a beautiful, ready-to-gift box. We do not yet have gift wrapping available or the ability to add a message to your order on our website, but we are working on it!',
      },
    ],
  },
]
