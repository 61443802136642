import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import FAQItems from '../FAQItems'
import { FAQ_QUESTIONS } from './constants.js'
import { PLUS_FAQ_QUESTIONS } from '../../templates/PlusProductPage/constants'
import { CAL_FAQ_QUESTIONS } from './cal-constants.js'
import { UNIFIED_FAQ_QUESTIONS } from './unified-constants'

import { DIGITAL_FAQ_QUESTIONS, FIRE_DIGITAL_FAQ_QUESTIONS } from './digital-constants'
import { Section, ContentContainer } from '../FAQs/styles'
import {
  CAL_PRODUCT_PAGE_FAQ_QUESTIONS,
  INTERNATIONAL_CAL_PRODUCT_PAGE_FAQ_QUESTIONS,
} from '../../templates/FrameProductPage/cal-constants'
import { IN_CA, IS_FOREIGN_COUNTRY } from '../../utils/constants'

const FAQSection = ({ page }) => {
  const { t } = useTranslation('common')

  const calendarProductPageFAQs = !IS_FOREIGN_COUNTRY
    ? CAL_PRODUCT_PAGE_FAQ_QUESTIONS
    : INTERNATIONAL_CAL_PRODUCT_PAGE_FAQ_QUESTIONS
  const QUESTION_OVERRIDES = {
    plus: PLUS_FAQ_QUESTIONS,
    calendar: CAL_FAQ_QUESTIONS,
    calendarProductPage: calendarProductPageFAQs,
    digital: DIGITAL_FAQ_QUESTIONS,
    'digital-fire': FIRE_DIGITAL_FAQ_QUESTIONS,
    unified: UNIFIED_FAQ_QUESTIONS,
    calMax: calendarProductPageFAQs,
  }

  const questions = QUESTION_OVERRIDES[page] || FAQ_QUESTIONS
  if (page === 'frame' && IN_CA) {
    questions[1].content.push({
      q: `What are Skylight's shipping methods and how long do they take?`,
      a: `Skylight works hard to package and ship your order in a timely manner. Orders placed on ca.skylightframe.com are shipped within Canada and fulfilled through Amazon's shipping service. \n \n Standard orders typically arrive within 3-5 business days, priority orders typically arrive within 2-3 business days, and expedited orders typically arrive within 1 business day. Due to domestic shipment, customs fees do not apply.`,
    })
  }
  return (
    <ContentContainer page={page}>
      {questions.map((section, j) => (
        <React.Fragment key={j}>
          {section.sectionTitle && (
            <Section digital={page === 'digital'}>
              <Trans>{section.sectionTitle}</Trans>
            </Section>
          )}
          {section.content.map((content, i) => (
            <FAQItems
              key={i + content.q}
              question={t(content.q)}
              answer={t(content.a)}
              text={t(content.text)}
              link={t(content.link)}
              punctuation={t(content.punctuation)}
              placement={content.placement}
              page={page}
            />
          ))}
        </React.Fragment>
      ))}
    </ContentContainer>
  )
}
FAQSection.propTypes = {
  page: PropTypes.string,
}
export default FAQSection
