import styled from 'styled-components'
import { breakpoints, BuyNowLink } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'
import { CenteredContent } from '../Hero/styles'

export const FAQSectionContainer = styled.div`
  background-color: ${(props) => props.theme.cream};
  position: relative;
`
export const FAQContainer = styled.div`
  @media (max-width: ${breakpoints.s}px) {
    margin: auto;
  }
`

export const Section = styled.h4`
  font-family: 'P22MackinacProBook';
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin: 40px 0px 0px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 26;
    line-height: 32px;
  }
`
export const Question = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  color: ${(props) => props.color};
  margin: 16px 0px 0px 0px;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 24px;
    margin: 12px 0px 0px 0px;
  }
`
export const Answer = styled.p`
  max-width: 620px;
  font-family: 'FilsonProBook';
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  padding: 16px 0px 16px 0px;
  margin: 0px;
  > span {
    display: block;
    min-height: 10px;
  }
  @media (max-width: 1366px) {
    max-width: 560px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
    line-height: 1.62;
    max-width: 90%;
    padding: 12px 0px 12px 0px;
  }
`
export const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  padding-top: 18px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 12px;
  }
`
export const QuestionRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  outline: none;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 12px;
  }
`
export const AnswerFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`
export const ContentContainer = styled.div`
  max-width: 780px;
  width: 90%;
  margin: 40px auto 60px;
`
export const Border = styled.div`
  border-bottom: 1.5px solid rgba(68, 68, 68, 0.1);
  padding: 0px;
  width: 100%;
  margin: 0px;
`

export const Message = styled.h1`
  font-size: 26px;
  line-height: 32px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
  }
`
export const MobileView = styled.div`
  display: none;
  text-align: center;
  margin-top: 50px;
  @media (max-width: ${breakpoints.m}px) {
    display: ${(props) => (props.hiddenOnMobile ? 'none' : 'block')};
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 0px;
  }
`
export const DesktopView = styled.div`
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
export const FaqBuyNowLink = styled(BuyNowLink)`
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.09px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.07px;
  }
`
export const ALink = styled.a`
  color: ${(props) => props.theme.coral};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
`

export const BuyNowButton = styled(CenteredContent)`
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    a {
      width: 100%;
      display: block;
    }
  }
`
export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.m}px) {
    padding: 20px 0px 0px;
  }
`

export const Button = styled.a`
  border-radius: 28px;
  background-color: ${(props) => props.ctaBgColor || props.theme.coral};
  outline: none;
  font-family: 'FilsonPro';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.09px;
  text-align: center;
  color: #ffffff;
  padding: 16px 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.1, props.ctaBgColor || props.theme.coral)};
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.ctaBgColor || props.theme.coral)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 15px;
  }
`
